<script setup lang="ts">
// Combined jackpots and winners
const { combinedJackpot } = await useGameCombinedJackpot()
const { data: winner } = await useGameLatestJackpotWinner()

const winnerListLimit = 9
const { data: winnersLatest } = await useGameWinnersLatest({ limit: winnerListLimit })
</script>

<template>
    <div>
        <div class="winners-lists__jackpot">
            <GameCombinedJackpot :jackpot="combinedJackpot || 0" />
            <div class="winners__latest-big">
                <GameWinnerTile
                    v-if="winner && winner.gameName"
                    :winner="winner"
                    is-big-win
                />
                <GameWinnerTileSkeleton v-else />
            </div>
        </div>
        <h2 class="use-brand-font use-brand-font--bold winners-now__head">
            Vindere lige nu
        </h2>
        <GameWinnerList
            v-if="winnersLatest?.length"
            class="winners__latest"
            :winners="winnersLatest"
            :fade="true"
        />
        <div
            v-else
            class="winners__latest"
            style="position: relative;"
        >
            <GameWinnerTileSkeleton
                v-for="index in winnerListLimit"
                :key="index"
            />
            <div class="winners__fade" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.winners-now__head {
    text-align: center;
    font-size: $font-size-xl;
    margin: 17px 0 10px;

    @media (min-width: $screen-lg) {
        margin: 33px 0 10px;
    }
}
</style>
